import React, { useEffect, useState, useContext } from "react";
import HOC from "../../Common/HOC";
import "./HomePage.css";
import Crousal from "../Crousal/Crousal";
import OurAstrologerCrousal from "../Crousal/OurAstrologerCrousal";
import TodayHoroscope from "../Crousal/TodayHoroscope";
import icons from "../../images/Icons/phone.png";
import icons2 from "../../images/Icons/chat.png";
import icons3 from "../../images/Icons/shop.png";
import icons5 from "../../images/Icons/live.png";
import { useNavigate } from "react-router-dom";
import { notificationHandler } from "../utils/Notification";
import { Skeleton } from "@mui/material";
import { UserContext } from "../../App";
import Carousel from "react-material-ui-carousel";
import homeapi from "../api/homeapi";
import Loder from "../Loder/Loder";
import UserRating from "../DialogeBox/UserRating";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import chat from "../../images/images/chat.png";
import talk from "../../images/images/talk.png";
import PartnerSlider from "../Crousal/PartnerSlider";
import { Helmet } from "react-helmet";
import { Description } from "@material-ui/icons";
import FAQ from "../AstrogurujiiFAQ/FAQ";
const HomePage = () => {
  const [isloading, setisloading] = useState(false);
  const [liveAstrologerArr, setliveAstrologerArr] = useState([]);
  const [AstrologerList, setAstrologerList] = useState("");
  const [BlogSectionArry, setBlogSectionArry] = useState([]);
  const [testimonialsArry, settestimonialsArry] = useState([]);
  const [channel_id, setchannel_id] = useState("");
  const { state, dispatch } = useContext(UserContext);
  const [rating, setrating] = useState(false);
  const [homepagebanner, sethomepagebanner] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    LiveAstroData();
  }, []);

  const ListSkeleton = ({ listsToRender }) => {
    return (
      <>
        {Array(listsToRender)
          .fill(1)
          .map((card, index) => (
            <div style={{ width: "40%", margin: "5px" }} key={index}>
              <Skeleton variant="rectangular" width="100%">
                <div style={{ paddingTop: "57%" }} />
              </Skeleton>
              <Skeleton />
              <Skeleton />
            </div>
          ))}
      </>
    );
  };

  const LiveAstroData = async () => {
    setisloading(true);
    try {
      const res = await homeapi();
      console.log(res);
      setchannel_id(res.data.channel_id);
      if (res.data.status) {
        if (res.data.is_open_rating == "Yes") {
          setrating(true);
        }
        setliveAstrologerArr(res?.data?.live);
        setBlogSectionArry(res?.data?.blog);
        setAstrologerList(res?.data?.astrologer);
        settestimonialsArry(res?.data?.testimonials);
        sethomepagebanner(res?.data?.banner);
        dispatch({
          type: "USER",
          payload: {
            ...state,
            notification: res.data.notifications,
            cartItemsLength: res.data.item_total,
            wallet: res.data.wallet,
          },
        });
        setisloading(false);
      } else {
        notificationHandler({ type: "danger", msg: res.data.message });
        setisloading(false);
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: "Network Error!" });
    }
  };

  const allservics = [
    {
      title: "Talk to Astrologer",
      link: "/talk-to-astrologer",
      icon: icons,
      des: "Journey into Self-Discovery through Astrology",
    },
    {
      title: "Chat with Astrologer",
      link: "/chat-with-astrologer",
      icon: icons2,
      des: "Real-Time Guidance from the Astral Realm",
    },
    {
      title: "Astro Gurujii Shop",
      link: "/astroshop",
      icon: icons3,
      des: "Shop Best Online Astrology Products And Services",
    },
    {
      title: "Live Astrologers",
      link: "/live_astrologer",
      icon: icons5,
      des: "Ask Your Questions Live",
    },
  ];

  const bannerdummy = [
    {
      img: chat,
    },
    {
      img: talk,
    },
  ];

  const [horoscopeFaq, sethoroscopeFaq] = useState([
    {
      show: true,
      heading: "<h3>Can I get a trusted and perfect horoscope or Kundali from the online app?</h3>",
      desc: "<p>Yes, our astrology app has been built with the guidance of many famous astrologers.  Under the experienced astrologer, our app generated the most accurate Kundali.</p>",
    },
    {
      show: false,
      heading: "<h3>To create a horoscope or Kundaliinformation doesthe astrology app need? </h3>",
      desc: "<p>Our Astrology app needs a few details about your birth date, time, and place.</p>",
    },
    {
      show: false,
      heading: "<h3>How can I consult astrologer? </h3>",
      desc: "<p>AstroGuruji astrologers are just a chat or call away. You can contact them anytime and our experienced astrologer will provide solutions for all your problems. </p>",
    },
    {
      show: false,
      heading: "<h3>What are the benefits of downloading the Astroguruji app? </h3>",
      desc: "<p>you are new to Astroguruji, then after downloading the app, get your Kundali free. You will also get the first call free to talk to our astrologer and can consult any life problems.</p>",
    },
    {
      show: false,
      heading: "<h3>Why to select AstroGuruji?</h3>",
      desc: "<p>AstroGuruji is the leading astrology organization. It provides many services like horoscope or Kundali making, Kundali matching, daily, weekly, or yearly horoscope, Numerology, Tarot card reading, shopping, blog reading, and many more services. The most important of all is that we have experienced astrologers to guide you .</p>",
    },
  ]);

  const showfaqfun = (index) => {
    horoscopeFaq[index].show = !horoscopeFaq[index].show;
    sethoroscopeFaq([...horoscopeFaq]);
  };
  return (
    <>
      <Helmet>
        <title>Talk to Astrologer | Online Astrology Consultation-First Call Free - AstroGurujii</title>
        <meta
          name="description"
          content="Unlock the secrets of your destiny with AstroGurujii! Talk to astrologers online for personalized insights. First call free. Your cosmic journey begins here."
        />
        <meta
          name="keywords"
          content="astrologer, astrologers, talk to astrologer, best astrologers, online astrologers, famous astrologer, astrologers in India, famous genuine astrologers, world famous genuine astrologers, astrologer online, Best astrologers near me, My horoscope online, Best online astrology website in India, Vastu astrology, Horoscope Astrology, Chat with astrologer, free astrology chat"
        />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large" />
        <link rel="canonical" href="https://astrogurujii.com" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="Website" />
        <meta property="og:title" content="Talk to Astrologer | Online Astrology Consultation-First Call Free - AstroGurujii" />
        <meta
          property="og:description"
          content="Unlock the secrets of your destiny with AstroGurujii! Talk to astrologers online for personalized insights. First call free. Your cosmic journey begins here."
        />
        <meta property="og:site_name" content="AstroGurujii" />
        <meta property="og:url" content="https://astrogurujii.com/" />
        <meta property="og:image" content="https://astrogurujii.com/static/media/talk.21350e863f884524bcad.png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="400" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Talk to Astrologer | Online Astrology Consultation-First Call Free - AstroGurujii" />
        <meta
          name="twitter:description"
          content="Unlock the secrets of your destiny with AstroGurujii! Talk to astrologers online for personalized insights. First call free. Your cosmic journey begins here."
        />
        <meta name="twitter:image" content="https://astrogurujii.com/static/media/talk.21350e863f884524bcad.png" />
      </Helmet>
      <div className="page-layout">
        <div className="homepage_padding">
          <div className="home-page-banner">
            <Carousel indicators={false}>
              {bannerdummy?.map((item, i) => (
                <a href={item.link} target="_blank">
                  <div className="home_dynamic_banner" style={{ cursor: "pointer" }}>
                    <img src={item.img} loading="lazy" style={{ width: "100%", height: "auto", borderRadius: "7px" }} />
                  </div>
                </a>
              ))}
            </Carousel>
          </div>
          <section className="today_horoscope_banner">
            <TodayHoroscope />
          </section>

          <section style={{ marginBlock: "2rem" }}>
            <div className="live_astrobg_banner" style={{ paddingBlock: "3rem", borderRadius: "6px" }}>
              <div className="" style={{ marginInline: "2rem" }}>
                <div className="live_services">
                  {allservics.map((data, index) => (
                    <div className="live_astrobg_banner_content ">
                      <div
                        className="live_astrobg_banner_content_card p-3"
                        // onClick={() => navigate(`${data.link}`)}
                      >
                        <div className="best_astro d-flex justify-content-center" style={{ translate: "0% -88%" }}>
                          <img src={data.icon} />
                        </div>
                        <div className="text-center mt-2 p-2" style={{ translate: "0% -50%" }}>
                          <div>
                            <span className="chatastro_heading">{data.title}</span>
                            <p>{data.des}</p>
                            <p style={{ color: "#fff" }}>
                              Read More <ArrowForwardIcon />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
          <section className="" style={{ paddingTop: "1rem", paddingInline: "1rem" }}>
            <Crousal />
          </section>
          <section className="ourastrologer mt-3 mb-5" style={{ padding: "2rem", backgroundColor: "#faebd7", borderRadius: "6px" }}>
            <OurAstrologerCrousal astro={AstrologerList} />
          </section>
        </div>
        <Loder loading={isloading} />
        <UserRating open={rating} channelId={channel_id} close={() => setrating(!rating)} type="call" />
        <div className="homepage_cotent">
          <h1 style={{ textAlign: "center" }}>AstroGuruJi - Online Astrologer consultation </h1>
          <p>
            Astrology is the study of the Stars and Planets and their effects on human lives and Earth events. Specialized person, the astrologer believes that
            the position of these Stars and Planets at the time of your birth impacts your personality, choices, habits, likes, and dislikes and predicts your
            future.{" "}
          </p>
          <p>
            It is developed thousands of years before in the earliestdevelopment of Egypt, China, and Greece. There are various schools of astrology worldwide,
            significantly in Egypt, China, India, and many Western countries.{" "}
          </p>
          <p style={{ paddingBottom: "2rem" }} s>
            Astrology is used by a human on various occasions to get predictions like marriage prediction, personality prediction, and future predictions, or to
            understand any events in their lives. Humans feel more confident by getting the forecasts and helping to make strong decisions. You can say that
            astrology has made humans’ lives easy.
          </p>
          <h2>The main areas of astrology are:</h2>
          <ul>
            <li>
              <b> Zodiac signs:</b> There are a total of 12 zodiac signs. And each signhas a specific start date and end datewith some personality qualities.
              These signs are Aries, Taurus, Gemini, Cancer, Leo, Virgo, Libra, Scorpio, Sagittarius, Capricorn, Aquarius, And Pisces.{" "}
            </li>
            <li>
              <b>Planets:</b> Astrology has shown the effects of all 8 planets named: Mercury, Venus, Mars, Jupiter, Saturn, Uranus, Neptune, and Pluto. It also
              involves the study of the Sun and Moon. Each Star and planetshavea varied impact on different aspects of human life.{" "}
            </li>
            <li style={{ paddingBottom: "2rem" }}>
              <b> Houses:</b> In astrology careers, relationships, home, business, love, family, health, and other important human life areas are divided into
              12 different houses. The position and stars and planets in the birth chart will provide a deep understanding of human personality and help in
              future predictions.
            </li>
          </ul>
          <h2>AstroGuruji- Many Astrology Services</h2>
          <p>
            Humansface many problems in their lives from childhood to the old age. All these problems may occur because of their actions or destiny. Many of
            these problems can be solved just by getting deep insights into the problems. Our astrologer will help you to provide deep insights by checking the
            stars and planets at the time of your birth or astrology birth chat or Kundali. After getting the details or the root cause you can make better
            decisions.{" "}
          </p>
          <p>
            At AstroGuruji, we value every customer. We provide the safety and security of their data and make them comfortable by providing the best online
            consultation and services.{" "}
          </p>
          <p>
            <b>We provide a wide range of different astrology services:</b>
          </p>
          <p>
            <b>Western astrology:</b> In this astrology, the Sun is the main component. Based on the Sun, it is divided into 12 Zodiac signs.
          </p>
          <p>
            <b>Vedic astrology:</b> In Vedic or Indian astrology, the Moon is the main component. Our Indian astrologer prepares your Kundali based on the
            movement of the moon and its phases.
          </p>
          <p style={{ paddingBottom: "2rem" }}>
            These are the most common astrology services. Apart from these, we offer many other services like Chinese astrology, Face reading, Vastu consultant,
            KP astrology, palm reading, Lal Kitab, Nadi astrology, and Psychic astrology. We provide these services to solve all your problems about marriage,
            love career, relationship, finance, business, and family.
          </p>
          <h2>Still searching for the best astrologer near me?</h2>
          <p>
            Astroguruji is the most genuine astrology website. Wehave the best and most experienced astrologers. Not only Vedic astrologers we have experience
            in Numerology, Tarot card readers, Western astrologers, Lal Kitab horoscope, Face Reader, Palmistry, Vaastu consultants, KP astrologers, Nadi
            astrologers, psychic astrologers. We have the best astrologer in India.
          </p>
          <p>
            Astrguruji also has an app that you can download and get our various services. Our app will work on both Android and iOS platforms. We have
            maintained many security checks to protect our customer data like encryption, validation, and registration, and use many secure protocols. On
            joining our app family for the first time you can get many benefits like a free astrology horoscope, and a free first astrology consultation.
          </p>
          <p>
            We also have genuine gemstones and many precious stones to attract positive energies. All gemstones are fully certified by the tested lab. You can
            purchase any gemstones, or precious stones from our app or our website.{" "}
          </p>
          <p>
            Ourexpertastrologers many times come to live to provide information about the sudden changes and transitions in the planet’s position. These sudden
            changes may create a great impact on your life, so our live astrologer provides remedies for that.{" "}
          </p>
          <p>
            Astrogurujiprovides many astrology services, and the talk-to-astrologer service is the best one. You can contact our online astrologer anytime for
            your problems. Even we have a facility for chat-to-astrologers.{" "}
          </p>
          <p style={{ paddingBottom: "2rem" }} ss>
            It is the misunderstanding that astrology can reveal the exact future. Astrology is the study of stars and plants and their effect on human lives.
            Based on the stars and planets, astrologers can forecast the future whether it has the same pattern or some different changes might come your way.
            Astrology does not guarantee future forecasts.
          </p>
          <h2>Astrology and its relation to your life</h2>
          <p>Astrology affects every aspect of human life. Let’s discuss some of them to make a clear picture of their relationship with us:</p>
          <p>
            <b>Astrology and our Career:</b> Astrology helps you to get afavorable time for a career change or any business opportunity. Our best astrologers
            online for careers also provide insight into your strengths, talents, and challenges. They will provide remedies to overcome all your career
            challenges.{" "}
          </p>
          <p>
            <b>Astrology and our Relationship complications:</b> Our best astrologers online for relationshipsprovide relationship remedies by exploring the
            major content (earth, fire, water, air) of your zodiac sign. In addition to that astrologers also check your Venus and Mars planets and talk about
            your approach towards love and relationship. Our astrologer will provide remedies to improve communication.
          </p>
          <p style={{ paddingBottom: "2rem" }}>
            <b> Astrology and Finance Issues:</b> If you are facing money or finance problems. Then astroGuruji is the best place for an online consultant. Our
            astrologer after checking your Kundali and the position of planets and stars, will tell you some remedies to solve all problems.{" "}
          </p>
        </div>
        <div className="faq-section py-3">
          <FAQ data={horoscopeFaq} onsubmit={(index) => showfaqfun(index)} />
        </div>
      </div>
    </>
  );
};

export default HOC(HomePage);
